import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { DeviceService } from 'src/app/core/services/device/device.service';
import { DeviceHelpersService } from 'src/app/core/services/helpers/device-helpers.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Device, DeviceOfferType } from 'src/app/core/types/device';
import { DeviceInfo, DeviceStaticInfo } from 'src/app/core/types/deviceInfo';
import { CheckOutType, DeviceOrder } from 'src/app/core/types/http/deviceOrder';
import { UserProfile } from 'src/app/core/types/userProfile';

@Component({
  selector: 'app-additional-order-info',
  templateUrl: './additional-order-info.component.html',
  styleUrls: ['./additional-order-info.component.less']
})
export class AdditionalOrderInfoComponent {
  profile: UserProfile = {} as UserProfile;
  device: Device = {} as Device;
  deviceName: string = 'LeakBot';
  discountDaysString: string = '';
  agreedToTerms: boolean = false;
  termsError: boolean = false;
  deviceInfo: DeviceInfo = {} as DeviceInfo;
  isLoading: boolean = false;

  AGREE_TO_TERMS_ERROR: string = 'Make sure you have read and agree to the terms and conditions.';

  constructor(
    private profileService: ProfileService,
    private deviceHelperService: DeviceHelpersService,
    private navService: NavigationService,
    private deviceService: DeviceService,
  ) {
    this.profileService.profile$.subscribe((profile: UserProfile) => {
      this.profile = profile;
    });
  }

  ngOnInit() {
    if (this.profile.devices) {
      this.device = this.deviceHelperService.getCurrentDevice(this.profile.devices, this.profile!.currentDevice);
      this.deviceName = this.device.vendorName;
      this.discountDaysString = this.getStringDays(this.device.discountDays);
      this.deviceInfo = DeviceStaticInfo[this.device.shortName];
    }
  }

  checkAgreedToTerms(agreedToTerms: boolean): void {
    this.termsError = !agreedToTerms;
  }

  goToCheckout(agreedToTerms: boolean): void {
    if (!agreedToTerms) {
      this.termsError = true;
    } else if (this.deviceInfo?.paidDeviceWebsite) {
      this.sendDeviceOrder();
    } else {
      this.navService.redirectError({
        error: {
          developerMessage: '',
          userMessage: '',
          messageID: '',
          code: 0
        }
      })
    }
  }

  getStringDays(discountDays: number): string {
    if (discountDays > 1) return `${discountDays} days`;
    else return `${discountDays} day`;
  }

  sendDeviceOrder() {
    if (this.agreedToTerms) {
      this.isLoading = true;

      const todaysDate: string = JSON.stringify(new Date());
      let order: DeviceOrder = {
        deviceTypeId: this.device.deviceTypeId.toString(),
        policyNumber: this.profile!.policyNumber,
        dataCollectionId: this.profile.dataCollectionId,
        programConsentFlag: "Y",
        programConsentDt: todaysDate.substring(1, todaysDate.length - 1),
        checkOutType: CheckOutType.PAID
      }

      this.deviceService.placeDeviceOrder(order).then((response: any) => {
        if(this.deviceName.toLowerCase() === 'resideo'){
          window.open(`${this.deviceInfo?.paidDeviceWebsite}?uniqueid=${this.profile?.dataCollectionId}`, '_blank')
        }
        else{
          window.open(`${this.deviceInfo?.paidDeviceWebsite}?id=${this.profile?.policyNumber}&activate=${this.device.partnerMemberId.substring(this.device.partnerMemberId.length - 8)}`, '_blank')
        }
      }).catch((err: HttpErrorResponse) => {
        this.navService.redirectError(err);
      }).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.termsError = true;
    }
  }
}
