export enum DeviceOfferType {
    COMPLIMENTARY = 'Complimentary',
    PURCHASE = 'Purchase',
}

export enum VendorName {
    TING = "ting",
    PHYN = 'phyn',
    LEAKBOT = 'leakbot',
    NOTION = 'notion',
    RESIDEO = 'resideo'
}

export class Device {
    shortName: VendorName = VendorName.LEAKBOT; // ex: leakbot (how app references device)
    fullName: string = ''; // ex. LeakBot leak detector (display name and short description)
    vendorName: string = ''; // ex. LeakBot (display name without description)
    description: string = '';
    cost: number = 0;
    discount: number = 0;
    selected: boolean = false;
    installationMethod: string = '';
    discountDays: number = 0;
    deviceTypeId: string = '';
    deviceType: string = '';
    offerType: string = '';
    partnerMemberId: string = '';
    options?: DeviceOptions;

    constructor();
    constructor(deviceResponse: any, isSelected?: boolean);

    constructor(deviceResponse?: any, isSelected?: boolean) {
        this.cost = deviceResponse.cost;
        this.discount = deviceResponse.discountPercentage;
        this.vendorName = deviceResponse.vendor;
        this.shortName = deviceResponse.vendor.toLowerCase();
        this.deviceTypeId = deviceResponse.deviceTypeId;
        this.deviceType = deviceResponse.deviceType;
        this.offerType = deviceResponse.offerType;
        this.installationMethod = deviceResponse.installationType;
        this.selected = isSelected ?? false;
        this.partnerMemberId = deviceResponse.partnerMemberId;
    }
}

// Device specific options, update as more options are available
export type DeviceOptions = {
    orderOptions: OrderOption[];
}

export type OrderOption = {
    name: string;
    buttonText: string;
    selected: boolean;
}

export type DeviceResponse = {
    deviceTypeId: string;
    deviceType: string;
    vendor: string;
    offerType: string;
    cost: number;
    discountAmount: number;
    installationType: string;
}

export type ProviderSearchResults = {
    policyNumber: string;
    propertyAddress: string;
    smartHome: string;
    provider: string;
    deviceType: string;
    status: string;
    enrollDate: Date;
    shipDate: Date;
    installDate: Date;
}
